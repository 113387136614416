import { Component, ElementRef, Renderer2 } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { FirestoreService } from "../../services/firestore.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-presences',
  templateUrl: './presences.component.html',
  styleUrl: './presences.component.scss'
})
export class PresencesComponent {
  groupedData: { [key: string]: any[] } = {};
  groupedByActvtyOrTaskWorkersMap = new Map();
  allWorkersList: any[];
  getActiveWorkerLength = 0;
  groupedWorkersMap = new Map();
  groupedBy: string

  constructor(private renderer: Renderer2,
    private elRef: ElementRef,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const key = this.route.snapshot.queryParamMap.get('key');
    const locationId = this.route.snapshot.queryParamMap.get('location_id');
    this.groupedBy = this.route.snapshot.queryParamMap.get('order_by').toLowerCase()
    this.getPresences(key, locationId);
  }

  async getPresences(castingKey, locationId) {
    try {
      await this.authService.signInAnonymously();
    } catch (error) {
      console.log(`Error:${JSON.stringify(error)}`);
    }

    console.log(`Getting sign-in token...`);
    this.firestoreService.getSignInToken(castingKey).subscribe(async (response) => {
      try {
        this.firestoreService.setClientId(response.clientId);
        await this.authService.signIn(response.customToken);
        console.log(`Signed in successfully`);
        this.firestoreService.getAllWorkersForLocationId(locationId).subscribe(presenceList => {
          // this.groupByGroupName(presenceList);
          this.loadPresencesData(presenceList);
        });
        return;
      } catch (error) {
        console.log(`Error:${JSON.stringify(error)}`);
      }
    });
  }

  loadPresencesData(workersList: any) {
    this.allWorkersList = workersList.sort((workerA: any, workerB: any) => {
      return workerA.name?.toLowerCase() < workerB.name?.toLowerCase() ? -1 : workerA.name?.toLowerCase() > workerB.name?.toLowerCase() ? 1 : 0;
    });
    this.getActiveWorkerLength = this.allWorkersList.filter(worker => worker.presenceState !== "NONE").length;
    this.groupedWorkersMap = new Map();

    this.groupedByActvtyOrTaskWorkersMap = new Map();

    for (const worker of workersList) {
      let groupSpecificWorkerArray = [];
      let actvtyOrTaskSpecificWorkerArray = [];

      if (!worker.workerGroupName) {
        worker.workerGroupName = 'NOGROUP';
      }

      if (!worker.lastTaskOrActivityName) {
        worker.lastTaskOrActivityName = 'NOGROUP_TASK_ACTVTY';
      }

      if (worker.workerGroupName) {
        groupSpecificWorkerArray = this.groupedWorkersMap.get(worker.workerGroupName);

        if (!groupSpecificWorkerArray) {
          groupSpecificWorkerArray = [];
        }
      }

      if (worker.lastTaskOrActivityName) {
        actvtyOrTaskSpecificWorkerArray = this.groupedByActvtyOrTaskWorkersMap.get(worker.lastTaskOrActivityName);
        if (!actvtyOrTaskSpecificWorkerArray) {
          actvtyOrTaskSpecificWorkerArray = [];
        }
      }
      actvtyOrTaskSpecificWorkerArray.push(worker);
      groupSpecificWorkerArray.push(worker);
      this.groupedWorkersMap.set(worker.workerGroupName, groupSpecificWorkerArray);
      this.groupedByActvtyOrTaskWorkersMap.set(worker.lastTaskOrActivityName, actvtyOrTaskSpecificWorkerArray);
    }

  }

  getActiveGroupedWOrker(getGroup: any) {
    return this.groupedWorkersMap.get(getGroup).filter(item => item.presenceState !== "NONE").length;
  }

  getKeyArrayFromMap() {
    const sortedNonGroupNames = Array.from(this.groupedWorkersMap.keys()).filter(key => key !== 'NOGROUP').sort((groupNameA: any, groupNameB: any) => {
      return groupNameA?.toLowerCase() < groupNameB?.toLowerCase() ? -1 : groupNameA?.toLowerCase() > groupNameB?.toLowerCase() ? 1 : 0;
    });
    if (this.groupedWorkersMap.get('NOGROUP')?.length > 0) {
      return [
        'NOGROUP',
        ...sortedNonGroupNames
      ]
    } else {
      return [
        ...sortedNonGroupNames
      ]
    }
  }

  getKeyArrayFromActvtyTaskMap() {
    const sortedNonGroupNames =
      Array.from(this.groupedByActvtyOrTaskWorkersMap.keys()).filter(key => key !== 'NOGROUP_TASK_ACTVTY').sort((taskOrActvtyA: any, taskOrActvtyB: any) => {
        return taskOrActvtyA?.toLowerCase() < taskOrActvtyB?.toLowerCase() ? -1 : taskOrActvtyA?.toLowerCase() > taskOrActvtyB?.toLowerCase() ? 1 : 0;
      });
    if (this.groupedByActvtyOrTaskWorkersMap.get('NOGROUP_TASK_ACTVTY')?.length > 0) {
      return [
        ...sortedNonGroupNames,
        'NOGROUP_TASK_ACTVTY'
      ]
    } else {
      return [
        ...sortedNonGroupNames
      ]
    }
  }

  getClassForWorker(worker) {
    switch (worker.presenceState) {
      case 'NONE':
        return 'presence-state-none';
      case 'START_DAY':
        return 'presence-state-start-day';
      case 'START_TASK':
        return 'presence-state-start-task';
      case 'END_DAY':
        return 'presence-state-end-day';
      case 'START_BREAK_PAID':
        return 'presence-state-start-break-paid';
      case 'END_BREAK_PAID':
        return 'presence-state-end-break-paid';
      case 'START_BREAK':
        return 'presence-state-start-break';
      case 'END_BREAK':
        return 'presence-state-end-break';
      case 'START_BREAK_UNPAID':
        return 'presence-state-start-break-unpaid';
      case 'END_BREAK_UNPAID':
        return 'presence-state-end-break-unpaid';
      default:
        return 'presence-state-none';
    }
  }
}
